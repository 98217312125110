import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';


export const onInitialClientRender = () => {
    // ... Your Intersection Observer logic if needed ...

    // Include the scroll.js script using Helmet
    const script = document.createElement('script');
    script.src = '/scroll.js'; // Assuming the scroll.js file is directly in the "static" folder
    script.type = 'text/javascript';
    document.body.appendChild(script);
};

export const wrapRootElement = ({ element }) => {
    // Use Helmet to include the script for SSR (optional but recommended for better performance)
    return (
        <>
            <Helmet>
                <script src="/scroll.js" type="text/javascript" />
            </Helmet>
            {element}
        </>
    );
};
